import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import Tag from '../../components/Tag'
import Table from '../../components/Table'
import Button from '../../components/Button'
import Drawer from '../../components/Drawer'
import AssessmentTypeSelect from '../../components/AssessmentTypeSelect'
import PerformanceChart from '../../components/charts/PerformanceChart'
import DecisionModal from '../../components/DecisionModal'

import Backend from '../../api/Backend'
import Utils from '../../components/Utils'

import Assessment from '../../applets/Assessment'
import { LangContext } from '../../components/Translation'
import translations from './translations'

import { useGlobalContext } from '../../components/AppContext'

import { Segmented } from 'antd'

const tabContexts = {
	ASSESSMENTS: 'Assessments',
	COMPLETED_ASSESSMENTS: 'Completed Assessments'
}

const DEFAULT_TOAST_TIMER = 5000

const transformedTabContext = Object.values(tabContexts)

const Assessments = ({ id }) => {
	const lang = React.useContext(LangContext)(translations)
	const { questionnaireStatuses } = useGlobalContext()

	const [assessmentsData, setAssessmentsData] = useState()
	const [showAssessmentTrigger, setShowAssessmentTrigger] = useState(false)
	const [selectedAssessment, setSelectedAssessment] = useState()
	const [assessmentToRemove, setAssessmentToRemove] = useState()
	const [startAssessments, setStartAssessments] = useState([])
	const [loading, setLoading] = useState(false)
	const [tabContext, setTabContext] = useState(tabContexts.ASSESSMENTS)
	const [showRemoveModal, setShowRemoveModal] = useState(false)

	const backend = new Backend()
	const utils = new Utils()

	const formatToDate = ({ value }) => {
		return utils.toDate({ dateString: value })
	}

	const refresh = () => {
		setLoading(false)
		backend.getPatientQuestionnaires({
			patientId: id,
			cb: (data) => {
				setAssessmentsData(
					data.map((d) => {
						const _d = { ...d, status: lang(d.status.toLowerCase()), duration: getDuration(d) }

						return _d
					})
				)
				setLoading(false)
			}
		})
	}

	useEffect(() => {
		refresh()
	}, [id])

	const removeAssessment = (request) => {
		const loadingToast = toast.loading(lang('request_processing'), { closeOnClick: true })

		backend.getPatientQuestionnaires({
			requestId: request.id,
			type: backend.type.DELETE,
			cb: () => {
				toast.update(loadingToast, {
					render: lang('finish_and_exit'),
					type: 'success',
					isLoading: false,
					closeOnClick: true,
					autoClose: DEFAULT_TOAST_TIMER
				})
				refresh()
			},
			cbError: () => {
				{
					toast.update(loadingToast, {
						render: lang('processing_error'),
						type: 'error',
						isLoading: false,
						closeOnClick: true,
						autoClose: DEFAULT_TOAST_TIMER
					})
				}
			}
		})
	}

	const getDuration = (row) => {
		let endDate = new Date(row.created_at)
		endDate.setMinutes(endDate.getMinutes() - (endDate.getTimezoneOffset() + 60))

		if (row.status === 'EXPIRED') {
			endDate.setDate(endDate.getDate() + 10)
		}
		

		if (row.status === 'PENDING' || row.status === 'OVERDUE') {
			endDate = new Date(Date.now())
		}

		// If there is an updated_at date, use that as the end date instead
		if (row.updated_at && row.status !== 'EXPIRED') {
			endDate = new Date(row.updated_at)
			endDate.setMinutes(endDate.getMinutes() - (endDate.getTimezoneOffset() + 60))
		}

		// Calculate the difference between the start and end dates
		let startDate = new Date(row.created_at)
		startDate.setMinutes(startDate.getMinutes() - (startDate.getTimezoneOffset() + 60))
		let diffTime = Math.abs(endDate - startDate)
		let diffInDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

		return diffInDays
	}



	const renderDuration = ({ value }) => {
		return <PerformanceChart max={20} performance={value} unit={{ singular: lang('day'), plural: lang('days') }} />
	}

	const handleAssessmentStart = () => {
		const assessments = startAssessments.map((assessment) => { 
			return { 
				demand: assessment.demand, 
				questionnaireId: assessment.key 
			}
		})
		backend.patientQuestionnaires({
			patientId: id,
			body: JSON.stringify(assessments),
			type: backend.type.CREATE,
			cb: refresh
		})
		setStartAssessments([])
	}

	const tabContextSwitcher = () => {
		return (
			<div>
				<Segmented options={transformedTabContext} value={tabContext} onChange={setTabContext} />
			</div>
		)
	}

	const patientsCompletedAssessments =
		assessmentsData &&
		assessmentsData
			?.filter((questionnaire) => questionnaire.status === lang(questionnaireStatuses.COMPLETED.toLowerCase()))
			?.sort((a, b) => a.questionnaire_id - b.questionnaire_id)

	if (!patientsCompletedAssessments) return null

	const getMaxQuestionnaireCount = () => {
		let qCount = 1
		let maxCount = 1

		let prevQuestionnaire
		for (let row of patientsCompletedAssessments) {
			if (row.questionnaire_id === prevQuestionnaire) {
				qCount += 1
			}

			if (row.questionnaire_id !== prevQuestionnaire) {
				qCount = 0
			}

			if (maxCount < qCount) {
				maxCount = qCount
			}
			prevQuestionnaire = row.questionnaire_id
		}
		return maxCount
	}

	const formattedData = () => {
		if (!patientsCompletedAssessments) {
			return []
		}
		let qArray = []
		let visitCount = 0
		for (let row of patientsCompletedAssessments) {
			if (qArray.find((obj) => obj.key === row?.title)) {
				// already visited, add a column
				visitCount += 1
				let index = qArray.findIndex((i) => i.key === row?.title)
				qArray[index][visitCount] = row || {}
			} else {
				// first time visiting, add a row + column
				visitCount = 0
				qArray.push({ key: row?.title, [visitCount]: row || {} })
			}
		}
		return qArray
	}

	const formattedTableData = formattedData()

	const extraVisits = () => {
		let extraArray = []
		const extraCount = getMaxQuestionnaireCount()
		for (let i = 1; i < extraCount + 1; i++) {
			extraArray.push({
				key: i,
				value: ` ${lang('visit', [i])}`,
				render: (obj) => {
					let qObject = obj.value || {}
					return qObject?.status ? (
						<Button
							disabled={qObject?.status !== lang(questionnaireStatuses.COMPLETED.toLowerCase())}
							onClick={() => {
								setSelectedAssessment(qObject?.id)
							}}>
							{qObject?.status}
						</Button>
					) : (
						''
					)
				}
			})
		}
		return extraArray
	}

	let completedAssessmentColumns = [
		{ key: 'key', value: 'title' },
		{
			key: 0,
			value: 'baseline',
			render: (obj) => {
				let qObject = obj?.value || {}
				return (
					<Button
						disabled={qObject?.status !== lang(questionnaireStatuses.COMPLETED.toLowerCase())}
						onClick={() => {
							setSelectedAssessment(qObject?.id)
						}}>
						{qObject?.status}
					</Button>
				)
			}
		}
	]
	let extra = extraVisits()
	completedAssessmentColumns.push(...extra)

	return (
		<>
			<Drawer
				width="90vw"
				autoclose
				open={selectedAssessment}
				onClose={() => setSelectedAssessment()}
				onOK={() => {
					setSelectedAssessment()
				}}
				text={{
					title: lang('assessment'),
					cancel: lang('cancel'),
					ok: lang('ok')
				}}>
				<Assessment requestId={selectedAssessment?.id} assessment={selectedAssessment} />
			</Drawer>
			<DecisionModal
				open={showRemoveModal}
				onOK={() => {
					removeAssessment(assessmentToRemove)
					setShowRemoveModal(false)
					setSelectedAssessment(null)
				}}
				onCancel={() => {
					setShowRemoveModal(false)
					setSelectedAssessment(null)
				}}
				okType="danger"
				text={{
					title: lang('remove_assessment'),
					ok: lang('remove'),
					details: lang('remove_question', { title: assessmentToRemove ? assessmentToRemove.title : '' })
				}}
			/>
			{tabContext === tabContexts.ASSESSMENTS ? (
				<>
					<Drawer
						width="90vw"
						autoclose
						open={showAssessmentTrigger}
						onClose={() => {
							setShowAssessmentTrigger(false)
							setStartAssessments([])
						}}
						onOK={() => {
							setShowAssessmentTrigger(false)
							handleAssessmentStart()
						}}
						text={{
							title: lang('start_assessment'),
							cancel: lang('cancel'),
							ok: lang('ok')
						}}>
						<AssessmentTypeSelect
							value={startAssessments}
							onChange={(assessments, demand) => {
								setStartAssessments(assessments)
							}}
							style={{ width: '100%' }}
						/>
					</Drawer>
					<Table
						loading={loading}
						title={tabContextSwitcher()}
						borderless={false}
						headless={false}
						buttons={[
							<>
								<Button className="mr10" type="refresh" onClick={refresh}></Button>
								<Button
									type="add"
									onClick={() => {
										setShowAssessmentTrigger(true)
									}}></Button>
							</>
						]}
						columns={[
							{ key: 'created_at', value: lang('requested'), render: formatToDate, dateSorter: 'created_at' },
							{ key: 'updated_at', value: lang('updated-at'), render: formatToDate, dateSorter: 'updated_at' },
							{ key: 'title', value: lang('title'), search: true, sorter: (a, b) => (a.title ? a.title.localeCompare(b.title) : b) },
							{ key: 'type', value: lang('type'), search: true, sorter: (a, b) => (a.type ? a.type.localeCompare(b.type) : b) },
							{
								key: 'resource',
								value: lang('initiator'),
								render: ({ value }) => {
									return value.initiatorType
								},
								sorter: (a, b) => (a.resource.initiatorType ? a.resource.initiatorType.localeCompare(b.resource.initiatorType) : b)
							},
							{
								key: 'demand',
								value: lang('demand'),
								render: ({ row }) => {
									return <Tag type={row.resource.demand}>{row.resource.demand ? lang(row.resource.demand.toLowerCase()) : ''}</Tag>
								},
								sorter: (a, b) => (a.resource.demand ? a.resource.demand.localeCompare(b.resource.demand) : b),
								searchWithRender: true
							},
							{
								key: 'status',
								value: lang('status'),
								render: ({ value }) => {
									return <Tag type={value}>{value}</Tag>
								},
								sorter: (a, b) => (a.status ? a.status.localeCompare(b.status) : b),
								searchWithRender: true
							},
							{ key: 'duration', value: lang('duration'), render: renderDuration, sorter: (a, b) => a.duration > b.duration },
							{
								key: 'actions',
								value: lang('actions'),
								render: ({ row }) => {
									return (
										<div className="inline">
											<Button
												disabled={row.status !== lang(questionnaireStatuses.COMPLETED.toLowerCase())}
												type="open"
												onClick={() => {
													setSelectedAssessment(row && row)
												}}
											/>
											<Button
												className="ml10"
												disabled={
													row.status !== lang(questionnaireStatuses.PENDING.toLowerCase()) &&
													row.status !== lang(questionnaireStatuses.OVERDUE.toLowerCase())
												}
												type="remove"
												onClick={() => {
													setAssessmentToRemove(row)
													setShowRemoveModal(true)
												}}
											/>
										</div>
									)
								}
							}
						]}
						data={assessmentsData}
					/>
				</>
			) : (
				<>
					<Table
						loading={loading}
						title={tabContextSwitcher()}
						borderless={false}
						headless={false}
						scroll={{ x: 'max-content' }}
						columns={completedAssessmentColumns}
						data={formattedTableData}></Table>
				</>
			)}
		</>
	)
}

export { Assessments }
